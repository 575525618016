<template>
  <div class="w-100">
    <div class="left-position ">
      <b-img
        :src="appLogoImage"
        alt="logo"
        height="40"
      />
    </div>
    <!-- <div class="right-position">
      <b-img
        :src="appLogoImageSecond"
        alt="logo"
        height="30"
      />
    </div> -->
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appLogoImageSecond } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      appLogoImageSecond,
    }
  },
}
</script>
<style lang="scss">
.grecaptcha-badge { visibility: hidden !important; }
</style>
